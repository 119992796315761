import { api } from "../utils/axios";

export async function sendReserve(formData) {

    const formDataObj = Object.fromEntries(formData)

    await api.post(
        "/landing/reserve-car",
        {
            plate: formDataObj.Placa,
            days: formDataObj.QuantidadeDeDiarias,
            company: {
                name: formDataObj.SolicitanteEmpresa,
                cnpj: formDataObj.CNPJEmpresa.replace(/[./-]/g, ''),
                phone: formDataObj.TelefoneEmpresa,
                email: formDataObj.EmailEmpresa
            },
            beneficiary: {
                name: formDataObj.Nome,
                email: formDataObj.Email,
                cpf: formDataObj.CPF.replace(/[.-]/g, ''),
                phone: formDataObj.Telefone,
                address: {
                    zip_code: formDataObj.CEP,
                    state: formDataObj.Estado,
                    city: formDataObj.Cidade,
                    neighborhood: formDataObj.Bairro,
                    street: formDataObj.Logradouro,
                    number: formDataObj.Numero,
                    complement: formDataObj.Complemento
                }
            },
            responsible: {
                name: formDataObj.NomeResponsavelCartao,
                cpf: formDataObj.CPFResponsavelCartao.replace(/[.-]/g, '')
            },
            address: {
                city: formDataObj.CidadeRetirada,
                state: formDataObj.EstadoRetirada
            },
            withdrawn: {
                date: formDataObj.DataRetirada,
                hour: formDataObj.HoraRetirada
            }
        }
    );
}
